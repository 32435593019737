export const loveMessages = [
  "Eres la luz que ilumina mi mundo.",
  "Tu sonrisa es mi mayor tesoro.",
  "En tus ojos encuentro la paz.",
  "Me pierdo en la melodía de tu voz.",
  "Tu amor me hace sentir invencible.",
  "Eres la mujer de mis sueños hechos realidad.",
  "Contigo el tiempo se detiene.",
  "Eres mi refugio en la tormenta.",
  "Eres la razón por la la que sonrío.",
  "Mi corazón te pertenece por completo.",
  "Eres mi musa, mi inspiración.",
  "A tu lado soy la mejor versión de mí mismo.",
  "Eres mi confidente, mi mejor amiga.",
  "Me completas como nadie más.",
  "Eres la dueña de mis pensamientos.",
  "No imagino mi vida sin ti.",
  "Te amo más que a las palabras.",
  "Eres mi todo, mi universo.",
  "Eres la mujer más hermosa que he visto.",
  "Me enamoras cada día más.",
  "Eres mi presente y mi futuro.",
  "Gracias por existir en mi vida.",
  "Eres mi compañera de aventuras.",
  "Eres mi hogar.",
  "Eres mi alma gemela.",
  "Eres mi ángel guardián.",
  "Eres mi todo y más.",
  "Te amo con locura.",
  "Eres mi reina, mi princesa.",
  "Eres la mujer perfecta para mí.",
  "Eres mi todo, mi amor.",
  "Te amo más que ayer, y menos que mañana.",
  "Eres mi aire, mi oxígeno.",
  "Eres mi canción favorita.",
  "Eres mi poesía hecha realidad.",
  "Eres mi razón de ser.",
  "Eres mi brújula, mi guía.",
  "Eres mi faro en la oscuridad.",
  "Eres mi fortaleza, mi refugio.",
  "Eres mi sonrisa, mi alegría.",
  "Eres mi paz, mi tranquilidad.",
  "Eres mi todo, mi amor eterno.",
  "Eres mi ayer, mi hoy y mi mañana.",
  "Te amo con todo mi corazón.",
  "Eres la mujer de mi vida.",
  "Eres mi único y verdadero amor.",
  "Te amaré por siempre y para siempre.",
  "Eres mi todo, mi mundo, mi universo.",
  "Te amo más que las palabras pueden expresar.",
  "Eres mi todo, mi razón de vivir.",
  "Eres mi amor verdadero, mi alma gemela.",
  "Eres mi todo, mi presente y mi futuro.",
  "Te amo más que a nada en el mundo.",
  "Eres mi todo, mi amor por siempre.",
  "Eres mi todo, mi vida entera.",
  "Te amo más que a las estrellas del cielo.",
  "Eres mi todo, mi amor infinito.",
  "Te amo más que a las palabras.",
  "Tu sonrisa es mi dosis diaria de felicidad.",
  "En tus ojos encuentro la paz que anhelo.",
  "Tu voz es melodía para mis oídos.",
  "Tu tacto enciende mi piel y mi alma.",
  "Me has robado el corazón y no lo quiero de vuelta.",
  "Contigo a mi lado, los sueños se hacen realidad.",
  "Eres mi confidente, mi mejor amiga, mi amante.",
  "A tu lado me siento fuerte e invencible.",
  "Eres la razón por la que sonrío cada día.",
  "Eres mi musa, mi inspiración.",
  "Gracias por existir en mi vida.",
  "Eres mi princesa, mi reina, mi diosa.",
  "Eres el aire que respiro, el agua que bebo.",
  "No puedo imaginar mi vida sin ti.",
  "Eres mi todo y mi nada.",
  "Eres mi compañera de aventuras.",
  "Contigo quiero envejecer y ver el mundo.",
  "Eres el amor de mi vida.",
  "Te amo por siempre y para siempre.",
  "Eres mi presente, mi pasado y mi futuro.",
  "Eres la mujer de mis sueños.",
  "Me haces sentir completo.",
  "Eres la mujer más hermosa del mundo.",
  "Eres mi todo y mi universo.",
  "Eres la estrella que guía mi camino.",
  "Eres mi refugio en la tormenta.",
  "Eres la paz en mi corazón.",
  "Eres la alegría de mi vida.",
  "Eres mi todo y más",
  "Eres mi oasis en el desierto de la vida.",
  "Tu amor es como una melodía que llena mi alma de armonía.",
  "En tus brazos encuentro el refugio que mi corazón necesita.",
  "Eres la luz que ilumina mi camino y me guía hacia la felicidad.",
  "Tu sonrisa es como un sol radiante que alegra mis días.",
  "Tu mirada me cautiva y me hace perder la noción del tiempo.",
  "Eres la dueña de mis pensamientos y la razón de mi ser.",
  "No puedo imaginar mi vida sin ti, eres mi todo.",
  "Te amo con la intensidad de mil soles y la profundidad del océano.",
  "Eres mi confidente, mi mejor amiga, mi amante y mi alma gemela.",
  "Cada día que pasa te amo más que ayer, y menos que mañana.",
  "Eres mi aire, mi oxígeno, la razón por la que respiro.",
  "Eres mi canción favorita, la poesía que me inspira.",
  "Eres mi fortaleza en los momentos difíciles, mi brújula en la oscuridad.",
  "Mi corazón te pertenece por completo, eres mi amor eterno.",
  "Eres la mujer de mis sueños hechos realidad, mi más grande tesoro.",
  "Gracias por existir en mi vida, eres el regalo más preciado que tengo.",
  "Te amo más que a las palabras, más que a las estrellas del cielo.",
  "Eres mi todo, mi universo, mi razón de vivir.",
  "Te amaré por siempre y para siempre, eres mi único y verdadero amor.",
  "Eres la mujer más hermosa que he visto, tanto por dentro como por fuera.",
  "Me haces sentir completo, como si una parte de mí faltara sin ti.",
  "Eres mi compañera de aventuras, mi confidente, mi todo.",
  "Juntos podemos lograr cualquier cosa, porque nuestro amor es más fuerte que cualquier obstáculo.",
  "Eres mi inspiración, mi musa, la fuerza que me impulsa a ser mejor cada día.",
  "Gracias por tu amor, tu apoyo y tu comprensión, eres la mejor mujer del mundo.",
  "Te amo con locura, con pasión, con todo mi corazón y mi alma.",
  "Eres mi adicción, no puedo dejar de pensar en ti ni un solo momento.",
  "Eres mi obsesión, mi todo, mi razón de ser.",
  "Te amo más que a las palabras, más que a la vida misma.",
  "Eres mi ángel guardián, mi protectora, mi luz en la oscuridad.",
  "Eres mi refugio en la tormenta, mi paz en medio del caos.",
  "Eres la alegría de mi vida, la sonrisa que ilumina mis días.",
  "Eres mi motivación, mi impulso para seguir adelante.",
  "Eres mi todo y más, no puedo imaginar mi vida sin ti.",
  "Te amo con cada fibra de mi ser, con cada latido de mi corazón.",
  "Eres mi presente, mi pasado y mi futuro, el único amor que he conocido.",
  "Eres la mujer perfecta para mí, la que completa mi alma.",
  "Gracias por ser parte de mi vida, por hacerme tan feliz.",
  "Te amo por siempre y para siempre, eres mi todo, mi amor eterno.",
  "Cada momento contigo es especial, un tesoro que guardo en mi corazón.",
  "Tu amor me hace sentir vivo, me llena de energía y positividad.",
  "Eres mi mejor amiga, mi amante, mi confidente, la persona en la que más confío.",
  "A tu lado me siento seguro, amado y apreciado.",
  "Eres la mujer más increíble que he conocido, me inspiras a ser mejor persona.",
  "Gracias por tu amor incondicional, por siempre estar ahí para mí.",
  "Te amo con locura, con pasión, con todo mi corazón y mi alma.",
  "Eres mi adicción, no puedo dejar de pensar en ti ni un solo momento.",
  "Eres mi obsesión, mi todo, mi razón de ser.",
  "Te amo más que a las palabras, más que a la vida misma.",
  "Eres la mujer de mis sueños, la que siempre he deseado tener a mi lado.",
  "Eres mi compañera perfecta, la persona que me complementa en todos los sentidos.",
  "Gracias por ser mi todo, por hacerme sentir tan feliz y amado.",
  "Te amo con locura, con todo mi corazón y mi alma.",
  "Eres mi presente, mi pasado y mi futuro, el único amor que he conocido.",
  "No puedo imaginar mi vida sin ti, eres mi todo.",
  "Te amaré por siempre y para siempre, eres mi amor eterno.",
  "Eres mi reina, mi princesa, mi diosa.",
  "Eres el aire que respiro, el agua que bebo.",
  "No puedo imaginar mi vida sin ti.",
  "Eres la melodía que armoniza mi corazón.",
  "Tu sonrisa es el sol que ilumina mis días.",
  "En tu mirada me pierdo y encuentro la felicidad.",
  "Eres la dueña de mis sueños y la reina de mi alma.",
  "Te amo con la intensidad de un volcán y la ternura de un colibrí.",
  "Eres mi confidente, mi mejor amiga, mi amante y mi cómplice.",
  "Cada día que pasa te amo más, eres mi presente, mi pasado y mi futuro.",
  "Eres mi aire, mi oxígeno, la razón por la que respiro.",
  "Eres mi inspiración, mi musa, la artista que pinta mi vida de colores.",
  "Gracias por existir en mi vida, eres el regalo más hermoso que he recibido.",
  "Eres la mujer más bella del mundo, tanto por dentro como por fuera.",
  "Me haces sentir completo, como si una parte de mí faltara sin ti.",
  "Eres mi compañera de aventuras, mi confidente, mi todo.",
  "Juntos podemos lograr cualquier cosa, porque nuestro amor es más fuerte que cualquier obstáculo.",
  "Eres mi brújula en la oscuridad, mi guía en el camino de la vida.",
  "Eres mi fortaleza en los momentos difíciles, mi refugio en la tormenta.",
  "Eres la alegría de mi vida, la sonrisa que ilumina mis días.",
  "Eres mi motivación, mi impulso para seguir adelante.",
  "Eres mi todo y más, no puedo imaginar mi vida sin ti.",
  "Te amo con cada fibra de mi ser, con cada latido de mi corazón.",
  "Eres mi ángel guardián, mi protectora, mi luz en la oscuridad.",
  "Eres mi paz en medio del caos, mi tranquilidad en la tempestad.",
  "Eres la mujer de mis sueños, la que siempre he deseado tener a mi lado.",
  "Eres mi compañera perfecta, la persona que me complementa en todos los sentidos.",
  "Gracias por ser mi todo, por hacerme sentir tan feliz y amado.",
  "Te amo con locura, con pasión, con todo mi corazón y mi alma.",
  "Eres mi adicción, no puedo dejar de pensar en ti ni un solo momento.",
  "Eres mi obsesión, mi todo, mi razón de ser.",
  "Te amo más que a las palabras, más que a la vida misma.",
  "Eres mi reina, mi princesa, mi diosa.",
  "Eres el aire que respiro, el agua que bebo, la luz que me guía.",
  "No puedo imaginar mi vida sin ti, eres mi todo y mi nada.",
  "Eres mi compañera de aventuras, mi confidente, mi mejor amiga.",
  "Contigo quiero envejecer y ver el mundo, juntos hasta el final.",
  "Eres mi amor eterno, el único que ha conquistado mi corazón.",
  "Me haces sentir vivo, me llenas de energía y positividad.",
  "Eres mi inspiración, mi musa, la fuerza que me impulsa a ser mejor persona.",
  "Gracias por tu amor incondicional, por siempre estar ahí para mí.",
  "Te amo con locura, con pasión, con todo mi corazón y mi alma.",
  "Eres mi adicción, no puedo dejar de pensar en ti ni un solo momento.",
  "Eres mi obsesión, mi todo, mi razón de ser.",
  "Te amo más que a las palabras, más que a la vida misma.",
  "Eres la mujer de mis sueños, la que siempre he deseado tener a mi lado.",
  "Eres mi compañera perfecta, la persona que me complementa en todos los sentidos.",
  "Gracias por ser mi todo, por hacerme sentir tan feliz y amado.",
  "Te amo con locura, con pasión, con todo mi corazón y mi alma.",
  "Eres mi adicción, no puedo dejar de pensar en ti ni un solo momento.",
  "Eres mi obsesión, mi todo, mi razón de ser.",
  "Te amo más que a las palabras, más que a la vida misma.",
];
